.App {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a1a1a;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.container {
  text-align: center;
}

h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.links {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.links a {
  color: white;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  transition: background 0.2s;
}

.links a:hover {
  background: rgba(255, 255, 255, 0.2);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 